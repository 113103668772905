@media only screen and (min-width: 1024px) and (-webkit-min-device-pixel-ratio : 1.5), only screen and (min-device-pixel-ratio : 1.5) {
  body {
    font-size: 11px !important;
  }
  
  .BrainhubCarousel__container {
    position: relative;
    top: -369px;
    z-index: 1;
    height: 370px;
  }
  
  #your_unique_start_date_id{
    font-size: 12px !important;
  }
  #your_unique_end_date_id{
    font-size: 12px !important;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@media (min-width: 1024px) {

.BrainhubCarousel__container {
  position: relative;
  top: -440px;
  /* opacity: 0.4; */
  z-index: 1;
  height: 370px;
  margin-top: 70px;

}
}

@media (max-width: 575.98px) {
  html{
    width: 100%; 
  }

  /* //CAROUSEL */

  .BrainhubCarousel__trackContainer{
    width: 70% !important; 
  }
  .BrainhubCarousel__customArrows button {
    background-color: #EDEDED !important;
    border-radius: 50%; 
    height: 20px;
    width: 20px;
    border: none;
    box-shadow: 0px 0px 0.3em 0em grey;
  }

  .Carousel {
    justify-content: space-evenly !important; 
    /* display: none !important; */
  }

}

body, html{
  margin:0;
  padding:0;
}

@media screen and (max-width: 350px) {

  #MainPhotoArea{
    padding: 0px;
    margin: 0px;
  }
}

.fullWidth {
  width: 100% !important; 
}

.modalStyle {
  background-color: #16a5a3;
}

/* svg {
  fill: #16a5a3 !important;
}
svg path {
  stroke: #16a5a3;
} */

body #loading {
  height: 100vh;
  overflow-y: hidden;
}
.loader-container {
  margin: auto; 
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}
.loader-container img {
  height: 145px;
  transform: rotate(269deg) translate(-50px, 0px)
  /* transform: translate(15px, 15px); */
}

.loader {
  margin: auto;
  border: 6px solid #f3f3f3;
  border-top: 6px solid black;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0%  { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
#loading {
  position: fixed; 
  z-index: 999; 
  height: 100vh;
  width: 100vw;
  background-color: black;
  display: table;
  overflow-y: hidden;
}

@keyframes fadeIn {
0% {opacity:1;}
100% {opacity:0;}
}

@-moz-keyframes fadeIn {
0% {opacity:1;}
100% {opacity:0;}
}

@-webkit-keyframes fadeIn {
0% {opacity:1;}
100% {opacity:0;}
}

@-o-keyframes fadeIn {
0% {opacity:1;}
100% {opacity:0;}
}

@-ms-keyframes fadeIn {
0% {opacity:1;}

100% {opacity:0;}
}