.tr{
    display: flex;
}

.CalendarMonth_table {
    border-collapse: collapse;
    border-spacing: 1;
}
.DateRangePicker {
    display: flex;
    flex-direction: column;
    background-color: transparent;
}

.DateInput_fangStroke {
    stroke: #16a5a3;
    fill: #16a5a3;
}

.DayPicker__withBorder {
    box-shadow: 0 2px 6px #16a5a380, 0 0 0 1px rgb(0 0 0 / 7%);
    border-radius: 6px;
    border-color: #16a5a3;
    border-width: 1px;
    border-style: solid;
}

.CalendarDay__selected_span, .CalendarDay__selected_span:active, .CalendarDay__selected_span:hover {
    background: #16a5a31f;
    border: 1px  double #16a5a31f;
    color: #484848;
}

.CalendarDay__hovered_span, .CalendarDay__hovered_span:active, .CalendarDay__hovered_span:hover {
    background: #16a5a31f;
    border: 1px  double #16a5a31f;
    color: #484848;
}

.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover {
    background: #16a5a3;
    border: 1px
    double #16a5a3;
    color: #484848;
}

.CalendarDay__selected_span {
    background: #16a5a31f;
    border: 1px double rgba(22, 165, 163, 0.12);
    color: #484848;
}

.DayPicker_transitionContainer {
    width: 315px !important;
}

.DateInput {
    margin: 0;
    padding: 0;
    background: #fff;
    position: relative;
    display: inline-block;
    width: 114px;
    vertical-align: middle;
}
/*
.DateInput_input {
    font-weight: 200;
    font-size: 16px;
    line-height: 14px;
    color: #484848;
    background-color: #fff;
    width: 100%;
    padding: 12px 12px 12px;
    border: 0;
    border-top: 0;
    border-right: 0;
    border-bottom: 2 px  solid transparent;
    border-left: 0;
    border-radius: 0;
}
*/

.DateInput_input {
    font-weight: 200;
    font-size: 18px;
    line-height: 24px;
    color: #484848;
    background-color: #fff;
    width: 100%;
    padding: 11px 8px 6px;
    border: 0;
    border-top: 0;
    border-right: 0;
    border-bottom: 2px solid transparent;
    border-left: 0;
    border-radius: 0;
}

@media screen and (max-device-width: 370px) {
    .DateRangePickerInput_calendarIcon {
        display: none !important;
    }
}

.DateRangePickerInput_calendarIcon {
    background: 0 0;
    border: 0;
    color: inherit;
    font: inherit;
    line-height: normal;
    overflow: visible;
    cursor: pointer;
    display: inline-block;
    vertical-align: middle;
    padding: 10px;
    margin: 0px !important;
}

.DateInput_input__focused {
    outline: 0;
    background: #fff;
    border: 0;
    border-top: 0;
    border-right: 0;
    border-bottom: 2px solid #16a5a3;
    border-left: 0;
}

/*
.DayPicker{
    border: 1px solid #20A6A3;
    border-radius: 10px;
}
.DayPickerKeyboardShortcuts_showSpan__bottomRight {
    display: none;
}
.DayPickerKeyboardShortcuts_show__bottomRight {
    bottom: 0;
    right: 0;
    display: none;
}

.DayPickerNavigation_button__default {
    border:none;
}
.DayPickerNavigation_button__default:hover {
    border:none;
}
.DayPickerNavigation_svg__horizontal {
    height: 19px;
    width: 19px;
    fill: #20A6A3;
    display: block;
}

.DateRangePickerInput {
    display: flex;
    width: 335px;
    justify-content: space-between;
    background-color: transparent;
    border: none;
}
.DateInput_input_2 {
    margin-left: -20px;
}
.DateInput_input_2 {
    margin-left: -10px;

}


.CalendarDay {
    border:none;
    margin-left: 10px;
}
.CalendarMonthGrid {
    background-color: transparent;
    text-align: left;
}
.CalendarDay__selected_span {
    color: #000;
    background-color: transparent;
    border: none;
    border-bottom: 2px solid #20A6A3;
    border-top: 2px solid #20A6A3 ;
}
.DateRangePickerInput_arrow_svg{
    display: none;
}
.DateInput_input__focused {
    border-bottom: none;
}
.DateInput_input{
    background-color: #16B7AB;
    color: white;
    border: none;
}

.DateInput_input::placeholder {
    color: white;
    font: normal normal 300 17px/30px Poppins;

}
.DateInput_fang {
    display: none;
}

.DateInput_fangStroke {
    display: none;
}


.DateInput_fangShape {
    display: none;
}
.CalendarDay {
    margin-left: 1px;
}
.CalendarDay__selected {
    color:black;
    background-color: transparent;
    border-radius: 50%;
    border-bottom: 2px solid #20A6A3;
    border-top: 2px solid #20A6A3;
}
.CalendarDay__selected_start {
    color:black;
    background-color: transparent;
    border: none;
    border-bottom: 2px solid #20A6A3;
    border-top: 2px solid #20A6A3;
    border-left:2px solid #20A6A3;
}
.CalendarDay:hover {
    background-color:#20A6A3;
    color: black;
    border:none;
}
.CalendarDay__selected_end {
    color:black;
    background-color: transparent;
    border: none;
    border-bottom: 2px solid #20A6A3;
    border-top: 2px solid #20A6A3;
    border-right: 2px solid #20A6A3;
}

.CalendarDay__selected_end:hover {
    border-bottom: 2px solid #20A6A3;
    border-top: 2px solid #20A6A3;
    border-right: 2px solid #20A6A3;
    border-radius: 10px;
    background-color: white;
}



.CalendarDay__selected:hover {
    color: black
}

.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
    color:black;
}

*/